import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
var routes = [{
  path: '/',
  name: 'Login',
  component: function component() {
    return import(
    /* webpackChunkName: "about" */
    '@v/login.vue');
  }
}, {
  path: '/distribution',
  name: 'distribution',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "about" */
    '@v/home.vue');
  }
}, {
  path: '/init',
  name: 'init',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "about" */
    '@v/station.vue');
  }
}, {
  path: '/menu',
  name: 'menu',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "about" */
    '@v/menu.vue');
  },
  children: [{
    path: '/station',
    name: 'station-home',
    component: function component() {
      return import(
      /* webpackChunkName: "about" */
      '@v/station-home');
    }
  }, {
    path: '/real_alarm_display',
    name: 'real_alarm',
    component: function component(resolve) {
      return require(["@v/monitoring/alarm.vue"], resolve);
    }
  }, {
    path: '/meter',
    name: 'meter',
    component: function component(resolve) {
      return require(["@v/monitoring/meter_info.vue"], resolve);
    }
  }, {
    path: '/eblock',
    name: 'eblock',
    component: function component(resolve) {
      return require(["@v/monitoring/eblock_info1.vue"], resolve);
    } //				component : resolve => require([ "@v/monitoring/eblock_info.vue" ], resolve)

  }, {
    path: '/pack_data',
    name: 'firefighting',
    component: function component(resolve) {
      return require(["@v/monitoring/firefighting.vue"], resolve);
    }
  }, {
    path: '/device-settings',
    name: 'device-settings',
    component: function component(resolve) {
      return require(["@v/monitoring/device-settings.vue"], resolve);
    }
  }, {
    path: '/energy_chain',
    name: 'energy_chain',
    component: function component(resolve) {
      return require(["@v/monitoring/energy_chain.vue"], resolve);
    }
  }, {
    path: '/energy_chain_real',
    name: 'energy_chain_real',
    component: function component(resolve) {
      return require(["@v/monitoring/energy_chain_real.vue"], resolve);
    }
  }, {
    path: '/charge_dis_record',
    name: 'charge_dis_record',
    component: function component(resolve) {
      return require(["@v/analysis/charge_dis_record.vue"], resolve);
    }
  }, {
    path: '/load_tracking',
    name: 'load_tracking',
    component: function component(resolve) {
      return require(["@v/analysis/load_tracking.vue"], resolve);
    }
  }, {
    path: '/power_statistics',
    name: 'power_analysis',
    component: function component(resolve) {
      return require(["@v/analysis/power_analysis.vue"], resolve);
    }
  }, {
    path: '/thermal_management',
    name: 'thermal_management',
    component: function component(resolve) {
      return require(["@v/analysis/thermal_management.vue"], resolve);
    }
  }, {
    path: '/voltage_management',
    name: 'voltage_management',
    component: function component(resolve) {
      return require(["@v/analysis/voltage_management.vue"], resolve);
    }
  }, {
    path: '/cell_health',
    name: 'cell_health',
    component: function component(resolve) {
      return require(["@v/analysis/cell_health_management.vue"], resolve);
    }
  }, {
    path: '/alarm_record',
    name: 'alarm_record',
    component: function component(resolve) {
      return require(["@v/analysis/alarm_record.vue"], resolve);
    }
  }, {
    path: '/operator_record',
    name: 'operator',
    component: function component(resolve) {
      return require(["@v/analysis/operator_record.vue"], resolve);
    }
  }, {
    path: '/income_statistics',
    name: 'income_chart',
    component: function component(resolve) {
      return require(["@v/analysis/income_chart.vue"], resolve);
    }
  }, {
    path: '/income_table',
    name: 'income_table',
    component: function component(resolve) {
      return require(["@v/analysis/income_table.vue"], resolve);
    }
  }, {
    path: '/power_measurement',
    name: 'power_measurement',
    component: function component(resolve) {
      return require(["@v/analysis/lucksheet.vue"], resolve);
    } //				component : resolve => require([ "@v/analysis/electricity_bill_list.vue" ], resolve)

  }, {
    path: '/equipment_configuration',
    name: 'equipment',
    component: function component(resolve) {
      return require(["@v/config/equipment.vue"], resolve);
    }
  }, {
    path: '/station_configuration',
    name: 'station',
    component: function component(resolve) {
      return require(["@v/config/station.vue"], resolve);
    }
  }, {
    path: '/electricity_price',
    name: 'electricity_price',
    component: function component(resolve) {
      return require(["@v/config/electricity_price.vue"], resolve);
    }
  }, {
    path: '/user_configuration',
    name: 'user',
    component: function component(resolve) {
      return require(["@v/config/user.vue"], resolve);
    }
  }, {
    path: '/configuration',
    name: 'system',
    component: function component(resolve) {
      return require(["@v/config/system_configuration.vue"], resolve);
    }
  }]
}]; // 路由配置

var RouterConfig = {
  mode: "history",
  routes: routes
};
var router = new VueRouter(RouterConfig); //router.beforeEach((to, from, next) => {
//	iView.LoadingBar.start();
//	next();
//});
//
//router.afterEach(() => {
//	iView.LoadingBar.finish();
//	window.scrollTo(0, 0);
//});

export default router;