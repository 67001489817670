import "D:\\workspace\\emind-ui-1.0\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\workspace\\emind-ui-1.0\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\workspace\\emind-ui-1.0\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\workspace\\emind-ui-1.0\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import './registerServiceWorker';
import echarts from 'echarts';
import form from 'jquery-form';
import Antd from 'ant-design-vue';
import axios from '@a/js/axios';
import VueAxios from 'vue-axios';
import ElementResizeDetectorMaker from "element-resize-detector";
Vue.prototype.$erd = ElementResizeDetectorMaker();
import { Icon } from 'ant-design-vue';
import iconFront from './assets/iconfonts/iconfont.js';
var myicon = Icon.createFromIconfontCN({
  scriptUrl: iconFront
});
Vue.prototype.$echarts = echarts;
Vue.component('my-icon', myicon);

Date.prototype.Format = function (fmt) {
  var weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
  var o = {
    "M+": this.getMonth() + 1,
    // 月份
    "d+": this.getDate(),
    // 日
    "W+": this.getDay(),
    // 星期
    "h+": this.getHours(),
    // 小时
    "m+": this.getMinutes(),
    // 分
    "s+": this.getSeconds(),
    // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3),
    // 季度
    "S": this.getMilliseconds() // 毫秒

  };
  fmt = fmt.replace(/yyyy/, this.getYear() + 1900);
  fmt = fmt.replace(/yy/, (this.getYear() + 1900).toString().substring(2, 4));
  fmt = fmt.replace(/MM/, this.getMonth() < 9 ? "0" + (this.getMonth() + 1) : this.getMonth() + 1);
  fmt = fmt.replace(/dd/, this.getDate() < 10 ? "0" + this.getDate() : this.getDate());
  fmt = fmt.replace(/WW/, weeks[this.getDay()]);
  fmt = fmt.replace(/hh/, this.getHours() < 10 ? "0" + this.getHours() : this.getHours());
  fmt = fmt.replace(/HH/, this.getHours() < 10 ? "0" + this.getHours() : this.getHours());
  fmt = fmt.replace(/mm/, this.getMinutes() < 10 ? "0" + this.getMinutes() : this.getMinutes());
  fmt = fmt.replace(/ss/, this.getSeconds() < 10 ? "0" + this.getSeconds() : this.getSeconds());
  return fmt;
};

import VueDraggableResizable from 'vue-draggable-resizable';
Vue.component('vue-draggable-resizable', VueDraggableResizable); //注入全局属性$message

import { message, notification } from 'ant-design-vue';
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
message.config({
  duration: 2,
  maxCount: 1
});
Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueAxios, axios);
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');