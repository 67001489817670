import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import axios from "axios";
import util from "axios";
import store from "../../store/store";
import router from "../../router"; // axios 配置

axios.defaults.timeout = 20000;
axios.defaults.headers.post["Content-Type"] = "application/json"; //axios.defaults.baseURL = "/emind";
// http request 拦截器

axios.interceptors.request.use(function (config) {
  if (localStorage.getItem("currentUser_token") == null || localStorage.getItem("currentUser_token") == "") {
    config.headers.Authorization = "bearer " + localStorage.getItem("currentUser_token");
  } else {}

  if (config.url != "/emind/auth/oauth/token") {
    var expireTime = localStorage.getItem('expireTime');

    if (!expireTime) {
      expireTime = "0";
    }

    expireTime = parseInt(expireTime);

    if (expireTime < new Date().getTime()) {
      localStorage.setItem("currentUser_token", null);
    } else {
      if (!config.url.startsWith("/emind/alarm/real/get/count")) {
        localStorage.setItem('expireTime', new Date().getTime() + 1000 * 60 * 30);
      }
    }
  }

  config.headers.Authorization = "bearer " + localStorage.getItem("currentUser_token");
  return config;
}, function (err) {
  return Promise.reject(err);
}); // http response 拦截器

axios.interceptors.response.use(function (response) {
  switch (response.data.status) {
    case "4001":
      store.commit("users/clearUser");
      router.replace({
        path: "/"
      });
      return;

    case "4003":
      store.commit("users/clearUser");
      router.replace({
        path: "/"
      });
      return;

    default:
      return response;
  }
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        if (error.response.data.error === 'invalid_grant') {
          if (error.response.config.url === '/emind/auth/oauth/token') {
            error.message = '用户名或密码错误！';
          } else {
            store.commit("users/clearUser");
            router.replace({
              path: "/"
            });
          }
        } else {
          error.message = '请求错误';
        }

        break;

      case 401:
        // 401 清除token信息并跳转到登录页面
        store.commit("users/clearUser");
        router.replace({
          path: "/"
        });
        break;

      case 403:
        // 403 无权限，跳转到首页
        router.replace({
          path: "/"
        });
        break;

      case 404:
        router.replace({
          path: "/error"
        });
        break;

      case 408:
        error.message = '请求超时';
        break;

      case 500:
        error.message = '服务器内部错误';
        break;

      case 501:
        error.message = '服务未实现';
        break;

      case 502:
        error.message = '网关错误';
        break;

      case 503:
        error.message = '服务不可用';
        break;

      case 504:
        error.message = '网关超时';
        break;

      case 505:
        error.message = 'HTTP版本不受支持';
        break;

      default:
        break;
    }
  }

  return Promise.reject(error);
});
export default axios;