//
//
//
//
//
//
//
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  data: function data() {
    return {
      locale: zhCN
    };
  }
};