import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import Vuex from "vuex";
import Vue from "vue";
import UsersModule from "./modules/UsersModule";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    users: UsersModule
  },
  state: {
    todos: [{
      id: 1,
      text: "...",
      done: true
    }, {
      id: 2,
      text: "...",
      done: false
    }],

    /*域名*/
    // 测试
    domainName: 'http://127.0.0.1:8090',
    userUrlPre: "http://127.0.0.1:8090/user/",
    //生产
    // domainName: "https://www.lovemtt.com",
    // userUrlPre: "https://www.lovemtt.com/page/user/",
    count: 0,
    errorMsg: '',
    loginStatus: false,
    localBreadcrumbs: null,

    /*登录界面判断是否显示错误提示*/
    ifSign: false
  },
  getters: {
    doneTodos: function doneTodos(state) {
      return state;
    },
    doneTodosCount: function doneTodosCount(state, getters) {
      return getters;
    },
    getTodoById: function getTodoById(state) {
      return function (id) {
        return state.todos.find(function (todo) {
          return todo.id === id;
        });
      };
    },
    getSign: function getSign(state) {
      return state.ifSign;
    },
    getErrorMsg: function getErrorMsg(state) {
      return state.errorMsg;
    },
    getLoginStatus: function getLoginStatus(state) {
      return state.loginStatus;
    },
    getLocalBreadcrumbs: function getLocalBreadcrumbs(state) {
      return state.localBreadcrumbs;
    },
    _isMobile: function _isMobile() {
      var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  },
  mutations: {
    add: function add(state) {
      state.count++;
    },
    increment: function increment(state, number) {
      state.count += number;
    },
    setSignTrue: function setSignTrue(state) {
      state.ifSign = true;
    },
    setErrorMsg: function setErrorMsg(state, msg) {
      state.errorMsg = msg;
    },
    setLoginStatus: function setLoginStatus(state, msg) {
      state.loginStatus = msg;
    },
    setLocalBreadcrumbs: function setLocalBreadcrumbs(state, localBreadcrumbs) {
      state.localBreadcrumbs = localBreadcrumbs;
    },
    setSignFalse: function setSignFalse(state) {
      state.ifSign = false;
    }
  },
  actions: {
    increment: function increment(context) {
      context.commit("add");
    }
  }
});