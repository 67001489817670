import "core-js/modules/es.function.name.js";
import axios from "axios";
import { message } from 'ant-design-vue';
import diagramConfig from '@/assets/json/data.json';
import Vue from 'vue';
export default {
  namespaced: true,
  state: {
    currentUser: {
      get UserName() {
        return localStorage.getItem("currentUser_name");
      },

      get UserToken() {
        return localStorage.getItem("currentUser_token");
      },

      get RefreshToken() {
        return localStorage.getItem("currentUser_refresh_token");
      }

    }
  },
  mutations: {
    setUser: function setUser(state, _ref) {
      var user_name = _ref.user_name,
          user_token = _ref.user_token,
          refresh_token = _ref.refresh_token;
      // 在这里把用户名和token保存起来
      localStorage.setItem("currentUser_name", user_name);
      localStorage.setItem("currentUser_token", user_token);
      localStorage.setItem("currentUser_refresh_token", refresh_token);
      localStorage.setItem('strategyId', -1);
      localStorage.setItem('stationId', -1);
    },
    clearUser: function clearUser(state) {
      localStorage.clear();
      sessionStorage.removeItem('emind.mapConfig');
    }
  },
  actions: {
    userLogin: function userLogin(context, _ref2) {
      var user_name = _ref2.user_name,
          user_password = _ref2.user_password,
          router = _ref2.router,
          me = _ref2.me;
      context.commit("setLoginStatus", true, {
        root: true
      });
      axios({
        method: "post",
        url: "/emind/auth/oauth/token",
        params: {
          username: user_name,
          password: user_password,
          grant_type: "password",
          scope: "all"
        },
        auth: {
          username: "client",
          password: "secret"
        }
      }).then(function (response) {
        message.success('登录成功');
        context.commit("setUser", {
          user_name: user_name,
          user_token: response.data && response.data.access_token,
          refresh_token: response.data && response.data.refresh_token
        });
        localStorage.setItem('expireTime', new Date().getTime() + 1000 * 60 * 30);
        axios({
          method: "get",
          cache: false,
          url: "/emind/user/users/user/info/-1"
        }).then(function (response) {
          if (response.data && response.data.data && response.data.data.type == 2) {
            context.commit("setLoginStatus", false, {
              root: true
            });
            router.push({
              path: '/distribution'
            });
          } else if (response.data && response.data.data && response.data.data.type == 1) {
            axios({
              method: 'get',
              url: '/emind/config/station/list'
            }).then(function (response) {
              if (response.data && response.data.data && response.data.code === '0') {
                context.commit("setLoginStatus", false, {
                  root: true
                });

                if (response.data && response.data.data && response.data.data.length > 0) {
                  var station = response.data.data[0];
                  localStorage.setItem('strategyId', station.strategyId);
                  localStorage.setItem('timePriceId', station.timePriceId);
                  localStorage.setItem('optimalStrategyId', station.optimalStrategyId);
                  localStorage.setItem('stationId', station.id);
                  localStorage.setItem('enableOperationDate', new Date(station.commissioningTime).Format("yyyy/MM/dd"));
                  localStorage.setItem('stationName', station.name);
                  localStorage.setItem('restModeType', station.restMode);
                  router.push({
                    path: '/station'
                  });
                  var config = diagramConfig;

                  for (var i = 0; i < config.length; i++) {
                    if (config[i].name === 'diagramData_' + station.id) {
                      localStorage.setItem('diagramData', JSON.stringify(config[i].diagramData));
                      localStorage.setItem('meterData', JSON.stringify(config[i].meterData));
                      break;
                    }
                  }

                  if (config.length == i) {
                    localStorage.setItem('diagramData', JSON.stringify(config[0].diagramData));
                    localStorage.setItem('meterData', JSON.stringify(config[0].meterData));
                  }
                } else {
                  me.$message.error('该用户没有设备权限', 2, function () {});
                }
              } else {
                me.$message.error('请求出错', 2, function () {});
              }
            }).catch(function (error) {
              context.commit("setErrorMsg", error.message, {
                root: true
              });
              context.commit("setSignTrue", null, {
                root: true
              });
              me.logining = false;
            });
          } else {
            context.commit("setErrorMsg", "该用户不存在", {
              root: true
            });
          }

          me.logining = false;
        }).catch(function (error) {
          context.commit("setErrorMsg", error.message, {
            root: true
          });
          context.commit("setLoginStatus", false, {
            root: true
          });
          context.commit("setSignTrue", null, {
            root: true
          });
          me.logining = false;
        });
        context.commit("setSignFalse", null, {
          root: true
        });
        context.commit("setErrorMsg", '', {
          root: true
        });
      }).catch(function (error) {
        context.commit("setErrorMsg", error.message, {
          root: true
        });
        context.commit("setLoginStatus", false, {
          root: true
        });
        context.commit("setSignTrue", null, {
          root: true
        });
        me.logining = false;
      });
    },
    loginOUt: function loginOUt(context, _ref3) {
      var router = _ref3.router;
      router.push({
        path: "/"
      });
      context.commit("clearUser");
    }
  }
};